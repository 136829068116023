import React, { FC, ReactNode, useMemo } from 'react'
import { PlayerClientContext } from './usePlayerClient'
import { PlayerClient } from '../sdk'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { useDelegateKeypair } from './useDelegateKeypair'

export interface PlayerClientProviderProps {
  children: ReactNode
}

export const PlayerClientProvider: FC<PlayerClientProviderProps> = ({ children }) => {
  const { delegateKeypair } = useDelegateKeypair()
  const { connection } = useConnection()
  const { publicKey } = useWallet()
  const playerClient = useMemo(
    () =>
      publicKey ? new PlayerClient(connection, publicKey, delegateKeypair ? delegateKeypair.publicKey : null) : null,
    [publicKey, connection, delegateKeypair]
  )

  return (
    <PlayerClientContext.Provider value={{ playerClient, playerPublicKey: publicKey }}>
      {children}
    </PlayerClientContext.Provider>
  )
}
