import { AnchorProvider, Program } from '@coral-xyz/anchor'
import { Connection, Keypair, PublicKey, SystemProgram, SYSVAR_RENT_PUBKEY } from '@solana/web3.js'
import { PROGRAM_ID } from '.'
import { IDL, Zedwars } from './zedwars'
import * as SPL from '@solana/spl-token'
import { Metaplex } from '@metaplex-foundation/js'
import { pdas } from './pda'
import * as web3 from '@solana/web3.js'
import * as anchor from '@coral-xyz/anchor'
import { EquipSlot, Skill, TileType } from './arg_types'
import { ASSOCIATED_TOKEN_PROGRAM_ID, createAssociatedTokenAccountInstruction, createInitializeMint2Instruction, createMintToInstruction, MintLayout, TOKEN_PROGRAM_ID } from '@solana/spl-token'

const TOKEN_METADATA_PROGRAM_ID = new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s');

const getMetadata = async (mint: PublicKey): Promise<PublicKey> => {
  return (
    PublicKey.findProgramAddressSync(
      [
        Buffer.from('metadata'),
        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
        mint.toBuffer()
      ],
      TOKEN_METADATA_PROGRAM_ID
    )
  )[0]
}

export const getTokenWallet = async (
  wallet: PublicKey,
  mint: PublicKey
) => {
  return (
    PublicKey.findProgramAddressSync(
      [wallet.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()],
      ASSOCIATED_TOKEN_PROGRAM_ID
    )
  )[0]
}
/**
 * PlayerClient is a wrapper around the Zedwars program
 */
export class PlayerClient {
  public program: Program<Zedwars>
  public playerPubkey: PublicKey
  public metaplex: Metaplex
  public delegatePubkey: PublicKey | null = null
  public config: any

  /**
   * The constructor
   * @param connection - The connection to use
   * @param playerPubkey - The player's pubkey
   */
  constructor(connection: Connection, playerPubkey: PublicKey, delegatePubkey: PublicKey | null = null) {
    this.playerPubkey = playerPubkey
    this.delegatePubkey = delegatePubkey
    this.program = new Program<Zedwars>(
      IDL,
      PROGRAM_ID,
      new AnchorProvider(
        connection,
        {
          publicKey: playerPubkey,
          signTransaction: async (tx) => tx,
          signAllTransactions: async (txs) => txs,
        },
        { commitment: 'confirmed' }
      )
    )
    this.metaplex = Metaplex.make(connection)
    this.config = this.program.account.config.fetch(pdas.config())
  }

  /**
   * Initialize a character account
   * It places the character on the map
   * @param x - The x coordinate of the character
   * @param y - The y coordinate of the character
   * @param isZombie - Whether the character is a zombie or not
   * @param characterMint - The mint address of the character NFT
   * @returns The transaction that can be used to call the characterInit instruction
   */
  async characterInit(x: number, y: number, isZombie: boolean, characterMint: PublicKey) {
    let config = await this.program.account.config.fetch(pdas.config())
    let tx = await this.program.methods
      .characterInit(isZombie)
      .accountsStrict({
        player: this.playerPubkey,
        playerAta: SPL.getAssociatedTokenAddressSync(characterMint, this.playerPubkey),
        characterMint: characterMint,
        characterMetadata: this.metaplex.nfts().pdas().metadata({ mint: characterMint }),
        characterMasterEdition: this.metaplex.nfts().pdas().masterEdition({ mint: characterMint }),
        characterCollectionNftMint: config.charactersCollectionMint,
        character: pdas.character(characterMint),
        mapTile: pdas.mapTile(x, y),
        config: pdas.config(),
        systemProgram: web3.SystemProgram.programId,
      })
      .transaction()
    return tx
  }

  /**
   * Move a character
   * @param characterMint - The mint address of the character NFT
   * @param toX - The x coordinate to move to
   * @param toY - The y coordinate to move to
   * @returns
   */
  async characterMove(toX: number, toY: number, characterMint: PublicKey) {
    let character = await this.program.account.character.fetch(pdas.character(characterMint))
    let tx = new web3.Transaction()
    tx.add(
      await this.program.methods
        .characterMove()
        .accountsStrict({
          character: pdas.character(characterMint),
          player: this.playerPubkey,
          playerAta: SPL.getAssociatedTokenAddressSync(characterMint, this.playerPubkey),
          origTile: pdas.mapTile(character.x, character.y),
          destTile: pdas.mapTile(toX, toY),
          session: this.delegatePubkey ? pdas.session(this.playerPubkey) : null,
          signer: this.delegatePubkey ? this.delegatePubkey : this.playerPubkey,
          config: pdas.config(),
          systemProgram: web3.SystemProgram.programId,
        })
        .transaction()
    )
    return await this.wrapWithReimburse(tx, 5000)
  }

  /**
   * Initiate a character search
   * @param characterMint - The mint address of the character NFT
   * @returns
   */
  async characterSearch(characterMint: PublicKey) {
    let character = await this.program.account.character.fetch(pdas.character(characterMint))
    let tx = new web3.Transaction()
    tx.add(
      await this.program.methods
        .characterSearch()
        .accountsStrict({
          character: pdas.character(characterMint),
          player: this.playerPubkey,
          playerAta: SPL.getAssociatedTokenAddressSync(characterMint, this.playerPubkey),
          systemProgram: web3.SystemProgram.programId,
          tile: pdas.mapTile(character.x, character.y),
          sysvarSlotHashes: web3.SYSVAR_SLOT_HASHES_PUBKEY,
          signer: this.delegatePubkey ? this.delegatePubkey : this.playerPubkey,
          session: this.delegatePubkey ? pdas.session(this.playerPubkey) : null,
          config: pdas.config(),
        })
        .transaction()
    )
    return await this.wrapWithReimburse(tx, 5000)
  }

  /**
   * Barricade a tile
   * @param characterMint - The mint address of the character NFT
   * @returns
   */
  async characterBarricade(characterMint: PublicKey) {
    let character = await this.program.account.character.fetch(pdas.character(characterMint))
    let tx = new web3.Transaction()
    tx.add(
      await this.program.methods
        .characterBarricade()
        .accountsStrict({
          character: pdas.character(characterMint),
          player: this.playerPubkey,
          playerAta: SPL.getAssociatedTokenAddressSync(characterMint, this.playerPubkey),
          tile: pdas.mapTile(character.x, character.y),
          sysvarSlotHashes: web3.SYSVAR_SLOT_HASHES_PUBKEY,
          signer: this.delegatePubkey ? this.delegatePubkey : this.playerPubkey,
          session: this.delegatePubkey ? pdas.session(this.playerPubkey) : null,
          config: pdas.config(),
          systemProgram: web3.SystemProgram.programId,
        })
        .transaction()
    )
    return await this.wrapWithReimburse(tx, 5000)
  }

  /**
   * Destroy a barricade
   * @param characterMint - The mint address of the character NFT
   * @returns
   */
  async characterDestroyBarricade(characterMint: PublicKey, targetX: number, targetY: number) {
    let tx = new web3.Transaction()
    tx.add(
      await this.program.methods
        .characterDestroyBarricade()
        .accountsStrict({
          character: pdas.character(characterMint),
          player: this.playerPubkey,
          playerAta: SPL.getAssociatedTokenAddressSync(characterMint, this.playerPubkey),
          tile: pdas.mapTile(targetX, targetY),
          sysvarSlotHashes: web3.SYSVAR_SLOT_HASHES_PUBKEY,
          signer: this.delegatePubkey ? this.delegatePubkey : this.playerPubkey,
          session: this.delegatePubkey ? pdas.session(this.playerPubkey) : null,
          config: pdas.config(),
          systemProgram: web3.SystemProgram.programId,
        })
        .transaction()
    )
    return await this.wrapWithReimburse(tx, 5000)
  }

  /**
   * Destroy a generator
   * @param characterMint - The mint address of the character NFT
   * @returns
   */
  async characterDestroyGenerator(characterMint: PublicKey, targetX: number, targetY: number) {
    let tx = new web3.Transaction()
    tx.add(
      await this.program.methods
        .characterDestroyGenerator()
        .accountsStrict({
          character: pdas.character(characterMint),
          player: this.playerPubkey,
          playerAta: SPL.getAssociatedTokenAddressSync(characterMint, this.playerPubkey),
          tile: pdas.mapTile(targetX, targetY),
          sysvarSlotHashes: web3.SYSVAR_SLOT_HASHES_PUBKEY,
          signer: this.delegatePubkey ? this.delegatePubkey : this.playerPubkey,
          session: this.delegatePubkey ? pdas.session(this.playerPubkey) : null,
          config: pdas.config(),
          systemProgram: web3.SystemProgram.programId,
        })
        .transaction()
    )
    return await this.wrapWithReimburse(tx, 5000)
  }

  /**
   * Attack a character
   * @param characterMint - The mint address of the character NFT
   * @param targetMint - The mint address of the target character NFT
   * @returns
   */
  async characterAttack(characterMint: PublicKey, targetMint: PublicKey) {
    let character = await this.program.account.character.fetch(pdas.character(characterMint))
    let targetCharacter = await this.program.account.character.fetch(pdas.character(targetMint))
    let tx = new web3.Transaction()
    tx.add(
      await this.program.methods
        .characterAttack()
        .accountsStrict({
          weapon: character.equippedItems.weapon != null ? pdas.item(character.equippedItems.weapon) : null,
          armor: targetCharacter.equippedItems.armor != null ? pdas.item(targetCharacter.equippedItems.armor) : null,
          signer: this.delegatePubkey ? this.delegatePubkey : this.playerPubkey,
          session: this.delegatePubkey ? pdas.session(this.playerPubkey) : null,
          player: this.playerPubkey,
          playerAta: SPL.getAssociatedTokenAddressSync(characterMint, this.playerPubkey),
          character: pdas.character(characterMint),
          tile: pdas.mapTile(character.x, character.y),
          targetTile: pdas.mapTile(targetCharacter.x, targetCharacter.y),
          config: pdas.config(),
          sysvarSlotHashes: web3.SYSVAR_SLOT_HASHES_PUBKEY,
          targetCharacter: pdas.character(targetMint),
          systemProgram: web3.SystemProgram.programId,
        })
        .transaction()
    )
    return await this.wrapWithReimburse(tx, 5000)
  }


  async renameCharacter(characterMint: PublicKey, newName: string) {
    let tx = new web3.Transaction()
    tx.add(
      await this.program.methods
        .characterRename(newName)
        .accountsStrict({
          signer: this.delegatePubkey ? this.delegatePubkey : this.playerPubkey,
          session: this.delegatePubkey ? pdas.session(this.playerPubkey) : null,
          player: this.playerPubkey,
          playerAta: SPL.getAssociatedTokenAddressSync(characterMint, this.playerPubkey),
          character: pdas.character(characterMint),
          config: pdas.config(),
          systemProgram: web3.SystemProgram.programId,
        })
        .transaction()
    )
    return await this.wrapWithReimburse(tx, 5000)
  }

  /**
   * Use an item on a character
   * @param characterMint - The mint address of the character NFT
   * @param targetCharacterMint - The mint address of the target character NFT
   * @param item_id - The id of the item to be used
   * @returns
   */
  async characterUseItem(characterMint: PublicKey, targetCharacterMint: PublicKey, item_id: number) {
    let character = await this.program.account.character.fetch(pdas.character(characterMint), 'confirmed')
    let tx = new web3.Transaction()
    tx.add(
      await this.program.methods
        .characterUseItem(item_id)
        .accountsStrict({
          player: this.playerPubkey,
          playerAta: SPL.getAssociatedTokenAddressSync(characterMint, this.playerPubkey),
          character: pdas.character(characterMint),
          item: pdas.item(item_id),
          tile: pdas.mapTile(character.x, character.y),
          targetCharacter: pdas.character(targetCharacterMint),
          tokenProgram: SPL.TOKEN_PROGRAM_ID,
          systemProgram: web3.SystemProgram.programId,
          session: this.delegatePubkey ? pdas.session(this.playerPubkey) : null,
          signer: this.delegatePubkey ? this.delegatePubkey : this.playerPubkey,
          config: pdas.config(),
        })
        .transaction()
    )
    return await this.wrapWithReimburse(tx, 5000)
  }

  /**
   * Equip an item
   * @param characterMint - The mint address of the character NFT
   * @param itemMint - The mint address of the item NFT
   * @returns
   */
  async characterEquipItem(characterMint: PublicKey, item_id: number) {
    const character = await this.program.account.character.fetch(pdas.character(characterMint), 'confirmed')
    let tx = new web3.Transaction()
    tx.add(
      await this.program.methods
        .itemEquip()
        .accountsStrict({
          player: this.playerPubkey,
          playerAta: SPL.getAssociatedTokenAddressSync(characterMint, this.playerPubkey),
          character: pdas.character(characterMint),
          item: pdas.item(item_id),
          signer: this.delegatePubkey ? this.delegatePubkey : this.playerPubkey,
          session: this.delegatePubkey ? pdas.session(this.playerPubkey) : null,
          config: pdas.config(),
          systemProgram: web3.SystemProgram.programId,
          tile: pdas.mapTile(character.x, character.y),
        })
        .transaction()
    )
    return await this.wrapWithReimburse(tx, 5000)
  }

  /**
   * Unequip an item
   * @param characterMint - The mint address of the character NFT
   * @param itemMint - The mint address of the item NFT
   * @returns
   */
  async characterUnequipItem(characterMint: PublicKey, slot: EquipSlot) {
    let tx = new web3.Transaction()

    tx.add(
      await this.program.methods
        .itemUnequip(slot)
        .accountsStrict({
          player: this.playerPubkey,
          playerAta: SPL.getAssociatedTokenAddressSync(characterMint, this.playerPubkey),
          character: pdas.character(characterMint),
          signer: this.delegatePubkey ? this.delegatePubkey : this.playerPubkey,
          session: this.delegatePubkey ? pdas.session(this.playerPubkey) : null,
          config: pdas.config(),
          systemProgram: web3.SystemProgram.programId,
        })
        .transaction()
    )
    return await this.wrapWithReimburse(tx, 5000)
  }

  /**
   * Destroy an item
   * @param characterMint - The mint address of the character NFT
   * @param itemMint - The mint address of the item NFT
   * @returns
   */
  async characterDestroyItem(characterMint: PublicKey, item_id: number) {
    let tx = new web3.Transaction()
    tx.add(
      await this.program.methods
        .itemDestroy(item_id)
        .accountsStrict({
          character: pdas.character(characterMint),
          player: this.playerPubkey,
          signer: this.delegatePubkey ? this.delegatePubkey : this.playerPubkey,
          session: this.delegatePubkey ? pdas.session(this.playerPubkey) : null,
          playerAta: SPL.getAssociatedTokenAddressSync(characterMint, this.playerPubkey),
          config: pdas.config(),
          item: pdas.item(item_id),
          systemProgram: web3.SystemProgram.programId,
        })
        .transaction()
    )
    return await this.wrapWithReimburse(tx, 5000)
  }

  /**
   * Create a session
   * Transfer lamports to the delegate account if needed
   *
   * @param delegatePubkey - The public key of the delegate account
   * @param seconds - The number of seconds the session should last
   * @param lamports - The number of lamports to transfer to the session account
   * @returns
   */
  async createSession(delegatePubkey: web3.PublicKey, seconds: number, lamports: number) {
    this.delegatePubkey = delegatePubkey
    let tx = new web3.Transaction()
    // Transfer lamports to delegate if needed
    let delegateBalance = await this.program.provider.connection.getBalance(this.delegatePubkey)
    let minRent = (await this.program.provider.connection.getMinimumBalanceForRentExemption(0)) + 5000
    if (delegateBalance === 0) {
      tx.add(
        web3.SystemProgram.createAccount({
          fromPubkey: this.playerPubkey,
          newAccountPubkey: this.delegatePubkey,
          lamports: (minRent * 2),
          space: 0,
          programId: web3.SystemProgram.programId,
        })
      )
    } else if (delegateBalance < (minRent * 2)) {
      tx.add(
        web3.SystemProgram.transfer({
          fromPubkey: this.playerPubkey,
          toPubkey: this.delegatePubkey,
          lamports: (minRent * 2) - delegateBalance,
        })
      )
    }
    // close existing session account if it exists
    if ((await this.program.provider.connection.getAccountInfo(pdas.session(this.playerPubkey))) !== null) {
      tx.add(
        await this.program.methods
          .sessionClose()
          .accountsStrict({
            session: pdas.session(this.playerPubkey),
            player: this.playerPubkey,
          })
          .transaction()
      )
    }
    // create session account
    tx.add(
      await this.program.methods
        .sessionInit({
          seconds: new anchor.BN(seconds),
          lamports: new anchor.BN(lamports),
        })
        .accountsStrict({
          session: pdas.session(this.playerPubkey),
          player: this.playerPubkey,
          systemProgram: web3.SystemProgram.programId,
          delegate: this.delegatePubkey,
        })
        .transaction()
    )

    return tx
  }

  /**
   * Close the session account
   * @returns
   */
  async closeSession() {
    return await this.program.methods
      .sessionClose()
      .accountsStrict({
        session: pdas.session(this.playerPubkey),
        player: this.playerPubkey,
      })
      .transaction()
  }

  /**
   * Reimburse the delegate account
   * @param lamports - The number of lamports to reimburse
   * @returns
   */
  async wrapWithReimburse(tx: web3.Transaction, lamports: number) {
    let wrappingTx = new web3.Transaction()
    if (this.delegatePubkey) {
      wrappingTx.add(
        await this.program.methods
          .sessionReimburse(new anchor.BN(lamports))
          .accountsStrict({
            session: pdas.session(this.playerPubkey),
            player: this.playerPubkey,
            systemProgram: web3.SystemProgram.programId,
            signer: this.delegatePubkey,
          })
          .transaction()
      )
    }
    wrappingTx.add(tx)
    return wrappingTx
  }

  async characterUnlockSkill(characterMint: PublicKey, skill: Skill) {
    let tx = new web3.Transaction()
    tx.add(
      await this.program.methods
        .characterUnlockSkill(skill)
        .accountsStrict({
          player: this.playerPubkey,
          session: this.delegatePubkey ? pdas.session(this.playerPubkey) : null,
          signer: this.delegatePubkey ? this.delegatePubkey : this.playerPubkey,
          systemProgram: web3.SystemProgram.programId,
          playerAta: SPL.getAssociatedTokenAddressSync(characterMint, this.playerPubkey),
          character: pdas.character(characterMint),
          config: pdas.config(),
        })
        .transaction()
    )
    return await this.wrapWithReimburse(tx, 5000)
  }

  async characterLoot(characterMint: PublicKey, targetCharacterMint: PublicKey, itemId: number) {
    const character = await this.program.account.character.fetch(pdas.character(characterMint))
    let tx = new web3.Transaction()
    tx.add(
      await this.program.methods
        .characterLoot(itemId)
        .accountsStrict({
          player: this.playerPubkey,
          session: this.delegatePubkey ? pdas.session(this.playerPubkey) : null,
          signer: this.delegatePubkey ? this.delegatePubkey : this.playerPubkey,
          systemProgram: web3.SystemProgram.programId,
          playerAta: SPL.getAssociatedTokenAddressSync(characterMint, this.playerPubkey),
          character: pdas.character(characterMint),
          targetCharacter: pdas.character(targetCharacterMint),
          config: pdas.config(),
          item: pdas.item(itemId),
          tile: pdas.mapTile(character.x, character.y),
          sysvarSlotHashes: web3.SYSVAR_SLOT_HASHES_PUBKEY,
        })
        .transaction()
    )
    return await this.wrapWithReimburse(tx, 5000)
  }

  async characterStandBackUp(characterMint: PublicKey) {
    let character = await this.program.account.character.fetch(pdas.character(characterMint))
    let tx = new web3.Transaction()
    tx.add(
      await this.program.methods
        .characterStandBackUp()
        .accountsStrict({
          signer: this.delegatePubkey ? this.delegatePubkey : this.playerPubkey,
          session: this.delegatePubkey ? pdas.session(this.playerPubkey) : null,
          player: this.playerPubkey,
          playerAta: SPL.getAssociatedTokenAddressSync(characterMint, this.playerPubkey),
          character: pdas.character(characterMint),
          item: pdas.item(500),
          config: pdas.config(),
          systemProgram: web3.SystemProgram.programId,
          tile: pdas.mapTile(character.x, character.y),
        })
        .transaction()
    )
    return await this.wrapWithReimburse(tx, 5000)
  }

  async itemMint(mint: Keypair, characterMint: PublicKey, itemId: number) {
    const character = await this.program.account.character.fetch(pdas.character(characterMint))
    let config = await this.program.account.config.fetch(pdas.config())

    const token = await getTokenWallet(
      this.playerPubkey,
      mint.publicKey
    )
    const metadata = await getMetadata(mint.publicKey)
    const rent = await this.program.provider.connection.getMinimumBalanceForRentExemption(
      MintLayout.span
    )

    const accounts = {
      config: pdas.config(),
      authority: pdas.config(),
      mint: mint.publicKey,
      metadata,
      player: this.playerPubkey,
      tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
      rent: SYSVAR_RENT_PUBKEY,
      character: pdas.character(characterMint),
      item: pdas.item(itemId),
      itemMint: pdas.itemMint(mint.publicKey),
      playerAta: SPL.getAssociatedTokenAddressSync(characterMint, this.playerPubkey),
      tile: pdas.mapTile(character.x, character.y),
      itemsCollectionMint: config.itemsCollectionMint,
      itemsCollectionMetadata: this.metaplex.nfts().pdas().metadata({ mint: config.itemsCollectionMint }),
      itemsCollectionMasterEdition: this.metaplex.nfts().pdas().masterEdition({ mint: config.itemsCollectionMint }),
      itemsCollectionAuthorityRecord: this.metaplex.nfts().pdas().collectionAuthorityRecord({ mint: config.itemsCollectionMint, collectionAuthority: pdas.config() }),
      masterEdition: this.metaplex.nfts().pdas().masterEdition({ mint: mint.publicKey }),
    }

    let tx = new web3.Transaction()
    tx.add(SystemProgram.createAccount({
      fromPubkey: this.playerPubkey,
      newAccountPubkey: mint.publicKey,
      space: MintLayout.span,
      lamports: rent,
      programId: TOKEN_PROGRAM_ID
    }),
      createInitializeMint2Instruction(
        mint.publicKey,
        0,
        this.playerPubkey, // mint authority
        this.playerPubkey, // freeze authority
        TOKEN_PROGRAM_ID,
      ),
      /* create an account that will hold your NFT */
      createAssociatedTokenAccountInstruction(
        this.playerPubkey, // payer
        token, // associated account
        this.playerPubkey, // owner
        mint.publicKey, //mint
        TOKEN_PROGRAM_ID, // token program
        ASSOCIATED_TOKEN_PROGRAM_ID // associated token program
      ),
      /* mint a NFT to the mint account */
      createMintToInstruction(
        mint.publicKey, // from
        token, // to
        this.playerPubkey, // authority
        1, // amount
        [], //multisigners
        TOKEN_PROGRAM_ID // program id
      ))
    tx.add(
      await this.program.methods
        .itemMint()
        .accounts(accounts)
        .transaction()
    );
    return tx
  }

  async newCharacter(mint: Keypair) {
    let config = await this.program.account.config.fetch(pdas.config())

    const token = await getTokenWallet(
      this.playerPubkey,
      mint.publicKey
    )
    const metadata = await getMetadata(mint.publicKey)
    const rent = await this.program.provider.connection.getMinimumBalanceForRentExemption(
      MintLayout.span
    )

    const accounts = {
      config: pdas.config(),
      authority: pdas.config(),
      mint: mint.publicKey,
      metadata,
      player: this.playerPubkey,
      tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
      rent: SYSVAR_RENT_PUBKEY,
      itemMint: pdas.character(mint.publicKey),
      playerAta: SPL.getAssociatedTokenAddressSync(mint.publicKey, this.playerPubkey),
      collectionMint: config.charactersCollectionMint,
      collectionMetadata: this.metaplex.nfts().pdas().metadata({ mint: config.charactersCollectionMint }),
      collectionMasterEdition: this.metaplex.nfts().pdas().masterEdition({ mint: config.charactersCollectionMint }),
      collectionAuthorityRecord: this.metaplex.nfts().pdas().collectionAuthorityRecord({ mint: config.charactersCollectionMint, collectionAuthority: pdas.config() }),
      masterEdition: this.metaplex.nfts().pdas().masterEdition({ mint: mint.publicKey }),
    }

    let tx = new web3.Transaction()
    tx.add(SystemProgram.createAccount({
      fromPubkey: this.playerPubkey,
      newAccountPubkey: mint.publicKey,
      space: MintLayout.span,
      lamports: rent,
      programId: TOKEN_PROGRAM_ID
    }),
      createInitializeMint2Instruction(
        mint.publicKey,
        0,
        this.playerPubkey, // mint authority
        this.playerPubkey, // freeze authority
        TOKEN_PROGRAM_ID,
      ),
      /* create an account that will hold your NFT */
      createAssociatedTokenAccountInstruction(
        this.playerPubkey, // payer
        token, // associated account
        this.playerPubkey, // owner
        mint.publicKey, //mint
        TOKEN_PROGRAM_ID, // token program
        ASSOCIATED_TOKEN_PROGRAM_ID // associated token program
      ),
      /* mint a NFT to the mint account */
      createMintToInstruction(
        mint.publicKey, // from
        token, // to
        this.playerPubkey, // authority
        1, // amount
        [], //multisigners
        TOKEN_PROGRAM_ID // program id
      ))
    tx.add(
      await this.program.methods
        .characterMint()
        .accounts(accounts)
        .transaction()
    );
    return tx
  }

  async characterDrag(characterMint: PublicKey, targetCharacterMint: PublicKey, targetX: number, targetY: number) {
    const characterAccount = await this.program.account.character.fetch(pdas.character(characterMint))

    let tx = new web3.Transaction()
    tx.add(
      await this.program.methods
        .characterDrag()
        .accountsStrict({
          signer: this.delegatePubkey ? this.delegatePubkey : this.playerPubkey,
          session: this.delegatePubkey ? pdas.session(this.playerPubkey) : null,
          player: this.playerPubkey,
          playerAta: SPL.getAssociatedTokenAddressSync(characterMint, this.playerPubkey),
          character: pdas.character(characterMint),
          tile: pdas.mapTile(characterAccount.x, characterAccount.y),
          config: pdas.config(),
          targetCharacter: pdas.character(targetCharacterMint),
          targetTile: pdas.mapTile(targetX, targetY),
          systemProgram: SystemProgram.programId,
        })
        .transaction()
    )
    return await this.wrapWithReimburse(tx, 5000)
  }

  async characterCraftItem(characterMint: PublicKey, itemId: number) {
    const characterAccount = await this.program.account.character.fetch(pdas.character(characterMint))
    let tx = new web3.Transaction()
    tx.add(
      await this.program.methods
        .itemCraft()
        .accountsStrict({
          config: pdas.config(),
          systemProgram: web3.SystemProgram.programId,
          item: pdas.item(itemId),
          player: this.playerPubkey,
          playerAta: SPL.getAssociatedTokenAddressSync(characterMint, this.playerPubkey),
          character: pdas.character(characterMint),
          signer: this.delegatePubkey ? this.delegatePubkey : this.playerPubkey,
          session: this.delegatePubkey ? pdas.session(this.playerPubkey) : null,
          tile: pdas.mapTile(characterAccount.x, characterAccount.y),
          sysvarSlotHashes: web3.SYSVAR_SLOT_HASHES_PUBKEY,
        })
        .transaction()
    )
    return await this.wrapWithReimburse(tx, 5000)
  }

  async characterRedeemItem(characterMint: PublicKey, im: PublicKey) {
    let ataItem = await getTokenWallet(this.playerPubkey, im)

    let itemMint = await this.program.account.itemMint.fetch(pdas.itemMint(im))

    return await this.program.methods
      .itemRedeem()
      .accountsStrict({
        player: this.playerPubkey,
        character: pdas.character(characterMint),
        config: pdas.config(),
        item: pdas.item(itemMint.id),
        itemMint: pdas.itemMint(im),
        systemProgram: web3.SystemProgram.programId,
        playerAtaCharacter: SPL.getAssociatedTokenAddressSync(characterMint, this.playerPubkey),
        playerAtaItem: ataItem,
        sftMint: im,
        tokenProgram: TOKEN_PROGRAM_ID,
      })
      .transaction()
  }

  async getItemsCollectionMint() {
    let config = await this.program.account.config.fetch(pdas.config())
    return config.itemsCollectionMint;
  }
  async getItemsCollectionMetadata() {
    let config = await this.program.account.config.fetch(pdas.config())
    return this.metaplex.nfts().pdas().metadata({ mint: config.itemsCollectionMint });
  }
  async getItemsCollectionMasterEdition() {
    let config = await this.program.account.config.fetch(pdas.config())
    return this.metaplex.nfts().pdas().masterEdition({ mint: config.itemsCollectionMint })
  }
  async getCollectionAuthorityRecord() {
    let config = await this.program.account.config.fetch(pdas.config())
    return this.metaplex.nfts().pdas().collectionAuthorityRecord({ mint: config.itemsCollectionMint, collectionAuthority: pdas.config() });
  }

  async getTileTypeIndex(tt: TileType): Promise<number> {
    if (tt.street) return 0;
    if (tt.hospital) return 1;
    if (tt.apartment) return 2;
    if (tt.policeStation) return 3;
    if (tt.warehouse) return 4;
    if (tt.fireStation) return 5;
    if (tt.zedCorp) return 6;
    if (tt.factory) return 7;
    if (tt.secretLocation) return 8;
    return 0;
  }

  async handleTransactionError(err: any, toast: any) {
    if (err.logs) {
      let errorMessage = err.logs.join().match(/Error Message: ([^,]+)/)[1];
      toast({
        title: errorMessage,
        status: 'error',
        duration: 5000,
      })
    } else {
      toast({
        title: 'Something went wrong, please check the dev console for more information',
        status: 'error',
        duration: 5000,
      })
      console.log(err);
    }
  }
}
