import { RegisterItemArgs } from './arg_types'

export const defaultItems: RegisterItemArgs[] = [
  {
    itemId: 1000,
    name: 'First Aid Kit',
    itemType: {
      consumable: {
        effectValue: 10,
        consumableType: {
          health: {},
        },
      },
    },
  },
  {
    itemId: 1001,
    name: 'Generator',
    itemType: {
      consumable: {
        consumableType: {
          generator: {},
        },
        effectValue: 1,
      },
    },
  },
  {
    itemId: 1002,
    name: 'Fuel Can',
    itemType: {
      consumable: {
        consumableType: {
          fuel: {},
        },
        effectValue: 24,
      },
    },
  },
  {
    itemId: 1003,
    name: 'Zombie Revival Syringe',
    itemType: {
      consumable: {
        consumableType: {
          revive: {},
        },
        effectValue: 1,
      },
    },
  },
  {
    itemId: 1004,
    name: 'Adrenaline Syringe',
    itemType: {
      consumable: {
        consumableType: {
          energy: {},
        },
        effectValue: 25,
      },
    },
  },
  {
    itemId: 1005,
    name: 'Experience Syringe',
    itemType: {
      consumable: {
        consumableType: {
          xp: {},
        },
        effectValue: 250,
      },
    }
  },
  {
    itemId: 5001,
    name: 'Common Pistol',
    itemType: {
      weapon: {
        weaponType: {
          firearm: {},
        },
        damage: 1,
        breakChance: 200,
        accuracy: 2500,
      },
    },
  },
  {
    itemId: 5002,
    name: 'Rare Pistol',
    itemType: {
      weapon: {
        weaponType: {
          firearm: {},
        },
        damage: 2,
        breakChance: 100,
        accuracy: 2500,
      },
    },
  },
  {
    itemId: 5003,
    name: 'Epic Pistol',
    itemType: {
      weapon: {
        weaponType: {
          firearm: {},
        },
        damage: 3,
        breakChance: 50,
        accuracy: 2500,
      },
    },
  },
  {
    itemId: 5004,
    name: 'Legendary Pistol',
    itemType: {
      weapon: {
        weaponType: {
          firearm: {},
        },
        damage: 4,
        breakChance: 10,
        accuracy: 2500,
      },
    },
  },
  {
    itemId: 5101,
    itemType: {
      weapon: {
        weaponType: {
          firearm: {},
        },
        damage: 2,
        breakChance: 200,
        accuracy: 2000,
      },
    },
    name: 'Common Shotgun',
  },
  {
    itemId: 5102,
    itemType: {
      weapon: {
        weaponType: {
          firearm: {},
        },
        damage: 3,
        breakChance: 100,
        accuracy: 2000,
      },
    },
    name: 'Rare Shotgun',
  },
  {
    itemId: 5103,
    itemType: {
      weapon: {
        weaponType: {
          firearm: {},
        },
        damage: 4,
        breakChance: 50,
        accuracy: 2000,
      },
    },
    name: 'Epic Shotgun',
  },
  {
    itemId: 5104,
    itemType: {
      weapon: {
        weaponType: {
          firearm: {},
        },
        damage: 5,
        breakChance: 10,
        accuracy: 2000,
      },
    },
    name: 'Legendary Shotgun',
  },
  {
    itemId: 5201,
    itemType: {
      armor: {
        armorType: {
          light: {},
        },
        defense: 1,
        breakChance: 200,
      },
    },
    name: 'Common Armor',
  },
  {
    itemId: 5202,
    itemType: {
      armor: {
        armorType: {
          light: {},
        },
        defense: 2,
        breakChance: 100,
      },
    },
    name: 'Rare Armor',
  },
  {
    itemId: 5203,
    itemType: {
      armor: {
        armorType: {
          light: {},
        },
        defense: 3,
        breakChance: 50,
      },
    },
    name: 'Epic Armor',
  },
  {
    itemId: 5204,
    itemType: {
      armor: {
        armorType: {
          light: {},
        },
        defense: 4,
        breakChance: 10,
      },
    },
    name: 'Legendary Armor',
  },
  {
    itemId: 5301,
    itemType: {
      weapon: {
        weaponType: {
          melee: {},
        },
        damage: 3,
        breakChance: 200,
        accuracy: 2500,
      },
    },
    name: 'Common Fire Axe',
  },
  {
    itemId: 5302,
    itemType: {
      weapon: {
        weaponType: {
          melee: {},
        },
        damage: 4,
        breakChance: 100,
        accuracy: 2500,
      },
    },
    name: 'Rare Fire Axe',
  },
  {
    itemId: 5303,
    itemType: {
      weapon: {
        weaponType: {
          melee: {},
        },
        damage: 5,
        breakChance: 50,
        accuracy: 2500,
      },
    },
    name: 'Epic Fire Axe',
  },
  {
    itemId: 5304,
    itemType: {
      weapon: {
        weaponType: {
          melee: {},
        },
        damage: 6,
        breakChance: 10,
        accuracy: 2500,
      },
    },
    name: 'Legendary Fire Axe',
  },
  {
    itemId: 5401,
    itemType: {
      weapon: {
        weaponType: {
          melee: {},
        },
        damage: 3,
        breakChance: 200,
        accuracy: 2750,
      },
    },
    name: 'Common Machete',
  },
  {
    itemId: 5402,
    itemType: {
      weapon: {
        weaponType: {
          melee: {},
        },
        damage: 4,
        breakChance: 100,
        accuracy: 2750,
      },
    },
    name: 'Rare Machete',
  },
  {
    itemId: 5403,
    itemType: {
      weapon: {
        weaponType: {
          melee: {},
        },
        damage: 5,
        breakChance: 50,
        accuracy: 2750,
      },
    },
    name: 'Epic Machete',
  },
  {
    itemId: 5404,
    itemType: {
      weapon: {
        weaponType: {
          melee: {},
        },
        damage: 6,
        breakChance: 10,
        accuracy: 2750,
      },
    },
    name: 'Legendary Machete',
  },
  {
    itemId: 5501,
    itemType: {
      weapon: {
        weaponType: {
          melee: {},
        },
        damage: 1,
        breakChance: 200,
        accuracy: 2500,
      },
    },
    name: 'Common Baseball Bat',
  },
  {
    itemId: 5502,
    itemType: {
      weapon: {
        weaponType: {
          melee: {},
        },
        damage: 2,
        breakChance: 100,
        accuracy: 2500,
      },
    },
    name: 'Rare Baseball Bat',
  },
  {
    itemId: 5503,
    itemType: {
      weapon: {
        weaponType: {
          melee: {},
        },
        damage: 3,
        breakChance: 50,
        accuracy: 2500,
      },
    },
    name: 'Epic Baseball Bat',
  },
  {
    itemId: 5504,
    itemType: {
      weapon: {
        weaponType: {
          melee: {},
        },
        damage: 4,
        breakChance: 10,
        accuracy: 2500,
      },
    },
    name: 'Legendary Baseball Bat',
  },
  {
    itemId: 5601,
    itemType: {
      weapon: {
        weaponType: {
          melee: {},
        },
        damage: 1,
        breakChance: 200,
        accuracy: 3000,
      },
    },
    name: 'Common Knife',
  },
  {
    itemId: 5602,
    itemType: {
      weapon: {
        weaponType: {
          melee: {},
        },
        damage: 2,
        breakChance: 100,
        accuracy: 3000,
      },
    },
    name: 'Rare Knife',
  },
  {
    itemId: 5603,
    itemType: {
      weapon: {
        weaponType: {
          melee: {},
        },
        damage: 3,
        breakChance: 50,
        accuracy: 3000,
      },
    },
    name: 'Epic Knife',
  },
  {
    itemId: 5604,
    itemType: {
      weapon: {
        weaponType: {
          melee: {},
        },
        damage: 4,
        breakChance: 10,
        accuracy: 3000,
      },
    },
    name: 'Legendary Knife',
  },
  {
    itemId: 5701,
    itemType: {
      weapon: {
        weaponType: {
          firearm: {},
        },
        damage: 2,
        breakChance: 200,
        accuracy: 2500,
      },
    },
    name: 'Common Hunting Rifle',
  },
  {
    itemId: 5702,
    itemType: {
      weapon: {
        weaponType: {
          firearm: {},
        },
        damage: 3,
        breakChance: 100,
        accuracy: 2500,
      },
    },
    name: 'Rare Hunting Rifle',
  },
  {
    itemId: 5703,
    itemType: {
      weapon: {
        weaponType: {
          firearm: {},
        },
        damage: 4,
        breakChance: 50,
        accuracy: 2500,
      },
    },
    name: 'Epic Hunting Rifle',
  },
  {
    itemId: 5704,
    itemType: {
      weapon: {
        weaponType: {
          firearm: {},
        },
        damage: 6,
        breakChance: 10,
        accuracy: 2500,
      },
    },
    name: 'Legendary Hunting Rifle',
  },
  {
    itemId: 6001,
    name: 'Common Backpack',
    itemType: {
      backpack: {
        size: 2,
      },
    },
  },
  {
    itemId: 6002,
    name: 'Rare Backpack',
    itemType: {
      backpack: {
        size: 3,
      },
    },
  },
  {
    itemId: 6003,
    name: 'Epic Backpack',
    itemType: {
      backpack: {
        size: 4,
      },
    },
  },
  {
    itemId: 6004,
    name: 'Legendary Backpack',
    itemType: {
      backpack: {
        size: 5,
      },
    },
  },
  {
    itemId: 500,
    name: 'Zombie Bite',
    itemType: {
      weapon: {
        weaponType: {
          zombieBite: {},
        },
        damage: 4,
        accuracy: 2500,
        breakChance: 0,
      },
    },
  },
  {
    itemId: 501,
    name: 'Zombie Claws',
    itemType: {
      weapon: {
        weaponType: {
          zombieClaw: {},
        },
        damage: 6,
        accuracy: 1500,
        breakChance: 0,
      },
    },
  },
]
