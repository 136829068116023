export const itemNames: { [key: string]: string } = {
    "1000": "First Aid Kit",
    "1001": "Generator",
    "1002": "Fuel Can",
    "1003": "Zombie Revival Syringe",
    "1004": "Adrenaline Syringe",
    "1005": "XP Booster",
    "5001": "Common Pistol",
    "5002": "Rare Pistol",
    "5003": "Epic Pistol",
    "5004": "Legendary Pistol",
    "5101": "Common Shotgun",
    "5102": "Rare Shotgun",
    "5103": "Epic Shotgun",
    "5104": "Legendary Shotgun",
    "5201": "Common Armor",
    "5202": "Rare Armor",
    "5203": "Epic Armor",
    "5204": "Legendary Armor",
    "5301": "Common Fire Axe",
    "5302": "Rare Fire Axe",
    "5303": "Epic Fire Axe",
    "5304": "Legendary Fire Axe",
    "5401": "Common Machete",
    "5402": "Rare Machete",
    "5403": "Epic Machete",
    "5404": "Legendary Machete",
    "5501": "Common Baseball Bat",
    "5502": "Rare Baseball Bat",
    "5503": "Epic Baseball Bat",
    "5504": "Legendary Baseball Bat",
    "5601": "Common Knife",
    "5602": "Rare Knife",
    "5603": "Epic Knife",
    "5604": "Legendary Knife",
    "5701": "Common Hunting Rifle",
    "5702": "Rare Hunting Rifle",
    "5703": "Epic Hunting Rifle",
    "5704": "Legendary Hunting Rifle",
    "6001": "Common Backpack",
    "6002": "Rare Backpack",
    "6003": "Epic Backpack",
    "6004": "Legendary Backpack",
}